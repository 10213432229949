<template lang="pug">
span.typing-text
  span(v-if="!interval" v-text="text" :style="style")
  span(v-text="current" :style="style")
</template>

<script>
export default {
  props: {
    texts: {
      type: Array,
      default: () => []
    },

    speed: {
      type: Number,
      default: 50
    },

    skips: {
      type: Number,
      default: 40
    }
  },

  data () {
    return {
      interval: null,
      step: 0,
      index: 0,
      skipped: 0
    }
  },

  computed: {
    item () {
      return this.texts[this.step]
    },

    text () {
      return typeof this.item === 'string'
        ? this.item
        : this.item.text
    },

    style () {
      return this.item?.style || {}
    },

    current () {
      return this.index > this.text?.length
        ? this.text?.substr(0, this.text?.length - (this.index - this.text?.length))
        : this.text?.substr(0, this.index)
    }
  },

  mounted () {
    this.bind()
  },

  beforeDestroy () {
    this.unbind()
  },

  methods: {
    bind () {
      this.unbind()
      this.index = this.text?.length
      this.interval = setInterval(this.tick, this.speed)
    },

    unbind () {
      if (this.interval) clearInterval(this.interval)
    },

    tick () {
      if (this.index === this.text?.length * 2) {
        this.step++
        if (this.step >= this.texts.length) this.step = 0
        this.index = 0
        this.skipped = 0
      } else if (this.index === this.text?.length && this.skipped < this.skips) {
        this.skipped++
      } else {
        this.index++
      }
    }
  }
}
</script>

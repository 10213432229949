<template lang="pug">
section.bloomberg-2024
  .container.framed
    .pf-flex.aic.panel
      .introduction
        .card(shadow="never")
          h1
            small Bloomberg Markets &mdash; The Close
            br
            | Watch our founder and CEO Larissa Russell on Bloomberg TV!
          //- el-button.hidden-sm-and-down(
            type="primary"
            @click="play"
            title="Watch our founder and CEO Larissa Russell on Bloomberg TV here!")
            i.bx.bx-play-circle.mr-1
            span Watch it now
          p
            a.linked(
              href="https://www.youtube.com/watch?v=HUnnAn3to6M&t=4793s"
              @click="$amplitude.track('CLICK_BLOOMBERG_ORIGINAL_VIDEO', { zone: $route.name })"
              target="_blank")
              span View full video on Youtube&nbsp;
              i.bx.bx-link-external
      .video
        video(
          ref="player"
          controls
          preload
          @play="played"
          @pause="playing = false"
          @stop="playing = false"
          poster="/img/poster.bloomberg-2024.webp")
          source(
            src="https://pod-public-static.s3.us-west-2.amazonaws.com/podfoods_bloomberg_the_close_20240419.mp4"
            type="video/mp4")
        .play(
          @click="play"
          v-if="!playing")
          i.bx.bx-play
</template>

<script>
export default {
  data () {
    return {
      playing: false
    }
  },

  methods: {
    play () {
      this.$refs.player.play()
      this.playing = true
    },

    played () {
      this.playing = true
      this.$amplitude.track('CLICK_BLOOMBERG_VIDEO', {
        zone: this.$route.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  margin: 4rem 0;
  .panel {
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  .introduction {
    flex: 1;
    min-width: 320px;
    h1 {
      font-size: 20px;
      line-height: 150%;
      font-weight: $--font-weight-normal;
      text-align: center;
      margin: 0 0 1em 0;
      @include media(md) {
        margin: 0 0 1em 0;
        font-size: 28px;
        text-align: left;
      }
      small {
        color: $--color-text-secondary;
        font-size: 14px;
        line-height: 150%;
      }
    }
    p {
      @include media(sm-down) {
        text-align: center;
      }
    }
    .linked {
      display: inline-flex;
      align-items: center;
    }
  }
  .video {
    flex: 2;
    min-width: 320px;
    position: relative;
    cursor: pointer;
    &:hover {
      .play {
        transform: translate(-50%, -50%) scale(1.1);
        background: rgba(black, .3);
      }
    }
    video {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 24px;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(black, .2);
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 3rem;
      color: white;
      padding-left: .15em;
      transition: all .2s ease-in-out;
      backdrop-filter: blur(8px);
      pointer-events: none;
    }
  }
}
</style>

<template lang="pug">
section.home-section.press-releases
  .container.framed
    .articles
      article(
        v-for="i, k in articles"
        :key="k")
        a(
          v-tooltip="{ content: i.title, classes: ['tc'] }"
          :href="i.url"
          target="_blank"
          rel="noopenner"
          :title="i.title")
          img(:src="i.logo" :alt="i.title" width="180" height="60" loading="lazy")
</template>

<script>
import { PRESS_RELEASES } from '~/settings'

export default {
  data () {
    return {
      articles: PRESS_RELEASES
    }
  }
}
</script>

<style lang="scss" scoped>
section.press-releases {
  margin: 2em 0;
  .articles {
    background: white;
    border-radius: 24px;
    display: grid;
    padding: 2em;
    gap: 1em;
    grid-template-columns: repeat(2, 1fr);
    @include media(sm) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2em;
      padding: 3em 4em;
    }
    @include media(md) {
      grid-template-columns: repeat(6, 1fr);
      padding: 4em 5em;
    }
    @include media(lg) {
      grid-template-columns: repeat(6, 1fr);
      gap: 1em;
    }
    a {
      display: block;
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>

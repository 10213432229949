<template lang="pug">
section.locations
  .container.framed
    .locations__layout
      .locations__map
        img(
          loading="lazy"
          src="/img/home/map.us.webp"
          width="712"
          height="453"
          alt="Available Nationwide")
        client-only
          .locations__marker(
            v-for="i, k in markers"
            :key="k"
            :style="{ left: `${i.left}%`, top: `${i.top}%`, width: `${i.size}%`, animationDelay: `${i.delay}ms` }")

      .locations__introduction
        h2 Available Nationwide
        p
          strong Distribution when and where you need it.
        p From progressive independents to mass market conventional stores, we service retailers across the continental United States with consolidated order fulfillment.
</template>

<script>
export default {
  data () {
    return {
      markers: [{
        top: 6.54,
        left: 10.27,
        size: 4,
        delay: 1994
      }, {
        top: 31.07,
        left: 5.65,
        size: 4,
        delay: 1903
      }, {
        top: 54.44,
        left: 16.07,
        size: 3,
        delay: 766
      }, {
        top: 76.17,
        left: 44.2,
        size: 2,
        delay: 670
      }, {
        top: 58.41,
        left: 49.4,
        size: 5,
        delay: 849
      }, {
        top: 63.08,
        left: 55.06,
        size: 2,
        delay: 591
      }, {
        top: 86.92,
        left: 79.17,
        size: 5,
        delay: 1402
      }, {
        top: 65.65,
        left: 77.98,
        size: 8,
        delay: 881
      }, {
        top: 44.39,
        left: 77.08,
        size: 7,
        delay: 965
      }, {
        top: 51.64,
        left: 72.62,
        size: 5,
        delay: 943
      }, {
        top: 52.1,
        left: 76.49,
        size: 4,
        delay: 1184
      }, {
        top: 22.66,
        left: 86.16,
        size: 4,
        delay: 705
      }, {
        top: 17.76,
        left: 90.63,
        size: 2,
        delay: 1747
      }, {
        top: 65.19,
        left: 60.12,
        size: 4,
        delay: 1464
      }, {
        top: 42.06,
        left: 26.64,
        size: 8,
        delay: 1833
      }, {
        top: 26.64,
        left: 20.24,
        size: 15,
        delay: 1317
      }, {
        top: 44.39,
        left: 57.14,
        size: 3,
        delay: 543
      }, {
        top: 15.19,
        left: 37.65,
        size: 2,
        delay: 1977
      }, {
        top: 16.36,
        left: 36.16,
        size: 5,
        delay: 530
      }, {
        top: 60.75,
        left: 27.23,
        size: 14,
        delay: 1284
      }, {
        top: 34.58,
        left: 75,
        size: 2,
        delay: 926
      }, {
        top: 21.5,
        left: 46.58,
        size: 2,
        delay: 879
      }, {
        top: 4.91,
        left: 50.3,
        size: 26,
        delay: 1876
      }, {
        top: 8.88,
        left: 25.45,
        size: 3,
        delay: 1468
      }, {
        top: 52.57,
        left: 63.69,
        size: 1,
        delay: 1849
      }]
    }
  },

  methods: {
    // track (e) {
    //   const width = e.target.offsetWidth
    //   const height = e.target.offsetHeight
    //   const top = e.offsetY / height * 100
    //   const left = e.offsetX / width * 100
    //   this.markers.push({
    //     top,
    //     left,
    //     size: 2
    //   })
    // },

    // resize (e) {
    //   e.preventDefault()
    //   const delta = e.deltaY > 0 ? 1 : -1
    //   if (!this.markers.length) return
    //   const marker = this.markers[this.markers.length - 1]
    //   this.$set(marker, 'size', marker.size + delta)
    // }
  }

}
</script>

<style lang="scss" scoped>
@keyframes pop {
  0% {
    background: rgba($--color-primary, 1);
    transform: scale(0);
  }
  100% {
    background: rgba($--color-primary, 0);
    transform: scale(1);
  }
}
.locations {
  &__layout {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    @include media(md) {
      flex-direction: row;
      gap: 64px;
    }
    > * {
      min-width: 0;
    }
  }
  &__map {
    flex: 4;
    position: relative;
    img {
      height: auto;
    }
  }
  &__marker {
    aspect-ratio: 1;
    border-radius: 100%;
    background: $--color-primary;
    position: absolute;
    border: 1px solid rgba($--color-primary, .25);
    animation-name: pop;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    transform: scale(0);
  }
  &__introduction {
    flex: 3;
    p {
      font-size: 16px;
      line-height: 150%;
      margin: 1em 0;
      color: $--color-text-secondary;
      strong {
        color: $--color-text-regular;
      }
    }
    a {
      margin-top: 48px;
    }
  }
}
</style>

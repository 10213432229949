<template lang="pug">
.rolling-text
  .rolling-text__inner(:style="style")
    span(
      v-for="i, k in texts"
      :key="k"
      v-if="i"
      v-text="i.text || i"
      :style="i.style || {}")
</template>

<script>
export default {
  props: {
    texts: {
      type: Array,
      default: () => []
    },

    duration: {
      type: Number,
      default: 1500
    },

    placeholder: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      interval: null,
      step: 0
    }
  },

  computed: {
    style () {
      return {
        transform: `translateY(-${100 / this.texts.length * this.step}%)`
      }
    }
  },

  mounted () {
    this.bind()
  },

  beforeDestroy () {
    this.unbind()
  },

  methods: {
    bind () {
      this.unbind()
      this.interval = setInterval(this.tick, this.duration)
    },

    unbind () {
      if (this.interval) clearInterval(this.interval)
    },

    tick () {
      this.step += 1
      if (this.step >= this.texts.length) this.step = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.rolling-text {
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  min-width: fit-content;
  &__inner {
    transition: transform .2s ease-in-out;
    > span {
      display: block;
    }
  }
}
</style>

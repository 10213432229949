
<template lang="pug">
section.testimonials(v-if="records.length")
  .container.framed
    h2 Hear from our customers

  .testimonials__grid
    .testimonials__item(
      v-for="i, k in items"
      :key="k")
      .customer
        .customer__cover(
          v-if="i && i.cover && i.cover.url"
          v-image="i && i.cover ? i.cover.url : null"
          :alt="i.name")
        .customer__body
          .customer__about
            .customer__avatar(
              v-if="i && i.logo && i.logo.url"
              v-image="i && i.logo ? i.logo.url : null"
              :alt="i.name")
            .customer__info
              .customer__broker(
                v-if="i.broker"
                v-text="i.broker")
              .customer__name(v-text="i.name")
            a.customer__link(
              v-if="i.link"
              :href="i.link"
              :title="i.name"
              target="_blank")
              i.bx.bx-link-external
          .customer__quote(
            v-text="i.content")

  el-button.mt-4.testimonials__load-more(
    v-if="showLoadMore"
    @click="loadMore")
    span Show more
    i.bx.bx-chevrons-down
</template>

<script>
import { take } from 'lodash'

export const ITEMS_PER_PAGE = 20

export default {
  props: {
    records: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      page: 1
    }
  },

  computed: {
    items () {
      return take(this.records, this.page * ITEMS_PER_PAGE)
    },

    showLoadMore () {
      return this.records.length > this.page * ITEMS_PER_PAGE
    }
  },

  methods: {
    loadMore () {
      this.page++
    }
  }
}
</script>

<style lang="scss" scoped>
.testimonials {
  $item-min-height: 264px;
  text-align: center;
  padding: 48px 0 0 0;
  cursor: default;
  h2 {
    font-size: 32px;
    line-height: 120%;
    width: 640px;
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
  }
  &__grid {
    margin-top: 40px;
    padding: 0 28px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    @include media(md) {
      padding: 0 40px;
    }
  }
  &__item {
    min-width: 0;
    height: $item-min-height;
  }
  &__load-more {
    margin: 32px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    i {
      color: $--color-primary;
      font-size: 20px;
    }
  }
  .customer {
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    text-align: left;
    background: #fbfdfd;
    height: 100%;
    min-height: $item-min-height;
    border: 2px solid white;
    transition: all .2s ease-in-out;
    &:hover {
      background: white;
      height: initial;
      z-index: 2;
      box-shadow: 0 20px 40px 0 rgba(#e5e5f8, .8);
      border-color: $--color-primary;
      .customer__quote {
        line-clamp: initial;
        -webkit-line-clamp: initial;
      }
    }
    &__cover {
      position: relative;
      background-color: $--color-placeholder;
      background-size: cover;
      background-position: center;
    }
    &__about {
      display: flex;
      gap: 16px;
      align-items: center;
      > * {
        min-width: 0;
      }
    }
    &__info {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    &__broker {
      font-size: 14px;
    }
    &__name {
      flex: 1;
      font-size: 16px;
      font-weight: $--font-weight-bold;
      color: $--color-text-regular;
    }
    &__link {
      font-size: 16px;
      color: $--color-primary;
    }
    &__avatar {
      $size: 36px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: $size;
      height: $size;
      border-radius: $size;
      box-shadow: $--box-shadow-card;
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: $--color-text-secondary;
      padding: 1.25em;
      font-size: 14px;
      line-height: 1.5em;
    }
    &__quote {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 8;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      font-size: 14px;
      line-height: 1.5em;
      min-height: 1.5em * 8;
    }
  }
}
</style>
